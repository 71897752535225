import React from "react";
import {Box, CircularProgress} from "@mui/material";

const LoadingWall = () => {
  return (
    <Box height={"100vh"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <CircularProgress size={70} />
    </Box>
  );
};

export default LoadingWall;
